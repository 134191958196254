<template>
    <div>
        <el-form ref="loginForm" :model="form" :rules="rules" label-width="80px" class="login-box">
            <h3 class="login-title">不轻文化管理系统</h3>
            <el-form-item label="账户" prop="name">
                <el-input type="text" placeholder="请输入账户" v-model="form.name"/>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" placeholder="请输入密码" v-model="form.password" @keyup.enter.native="onSubmit('loginForm')"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit('loginForm')">登录</el-button>
            </el-form-item>
        </el-form>

        <el-dialog
            title="温馨提示"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <span>请输入账号和密码</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {LOGIN} from '../api/auth'
import {
    GET_USER_PERMISSIONS
} from '../api/user'
export default {
    name: "Login",
    data() {
        return {
        form: {
            name: '',
            password: ''
        },

        // 表单验证，需要在 el-form-item 元素中增加 prop 属性
        rules: {
            name: [
            {required: true, message: '账户不可为空', trigger: 'blur'}
            ],
            password: [
            {required: true, message: '密码不可为空', trigger: 'blur'}
            ]
        },

        // 对话框显示和隐藏
        dialogVisible: false
        }
    },
    mounted(){
        console.log('token', sessionStorage.getItem('token'))
        console.log('tokenTime', sessionStorage.getItem('tokenTime'))
    },
    methods: {
        onSubmit(formName) {
            // if(this.form.name == 'admin'){
                // 为表单绑定验证功能
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
                        // this.$router.push("/main");
                        LOGIN({name: this.form.name, password: this.form.password})
                            .then(res => {
                                console.log('login success', res)
                      
                                GET_USER_PERMISSIONS()
                                    .then(res => {
                                        this.$message.success('登陆成功')
                                        sessionStorage.setItem('permissions', JSON.stringify(res.data.data.rolePermissions))
                                        sessionStorage.setItem('userid', res.data.data.id)
                                        sessionStorage.setItem('username', res.data.data.name)
                                        sessionStorage.setItem('show_name', res.data.data.show_name)
                                        sessionStorage.setItem('userrole', res.data.data.role)
                                        this.$router.push('/appointment')
                                    })
                            })
                            .catch(err => {
                                console.error('login fail', err)
                                this.$message.error(err.response.data.error)
                            })
                    } else {
                        this.dialogVisible = true;
                        return false;
                    }
                });
            // }
        },
        handleClose(){
            console.log('handleClose')
        },
    }
}
</script>

<style lang="scss" scoped>
.login-box {
    border: 1px solid #DCDFE6;
    width: 350px;
    margin: 180px auto;
    padding: 35px 35px 15px 35px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: 0 0 25px #909399;
}

.login-title {
    text-align: center;
    margin: 0 auto 40px auto;
    color: #303133;
}
</style>